import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import axios from 'axios';
import {v4 as uuidv4} from "uuid";
import {Button, Card, Checkbox, Form, Input, Layout, message, Modal, Typography} from 'antd';

import DropDownMenuLanguage from "./components/DropDownMenuLanguage";

const {Link, Text} = Typography;
const {Header, Content, Footer, Sider} = Layout;
const versionNumber = "v1.5.3";

function getOperatingSystem() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('win')) return 'Windows';
    if (userAgent.includes('mac')) return 'MacOS';
    if (userAgent.includes('linux')) return 'Linux';
    if (/iphone|ipad|ipod/.test(userAgent)) return 'iOS';
    if (userAgent.includes('android')) return 'Android';

    return 'Unknown';
}


const LoginPage = ({setLocale}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [resetPasswordForm] = Form.useForm();

    const [csrfToken, setCsrfToken] = useState(null);
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [loginLoading, setLoginLoading] = useState(false);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);


    useEffect(() => {
        axios.get('/api/get_csrf_token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            })
            .catch(error => console.error('Error fetching CSRF token:', error));
    }, []);

    useEffect(() => {
        const storedUserInfo = cookies.userInfo || null;
        if (storedUserInfo) {
            if (storedUserInfo.versionNumber !== versionNumber) {
                warningMessage(intl.formatMessage({id: 'main.newVersionLoginAgain'}));
            } else {
                navigate('/dashboard', {replace: true});
            }
        }
    }, [navigate]);


    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const onResetPassword = (values) => {
        if (values.password !== values.confirmPassword) {
            errorMessage(intl.formatMessage({id: 'loginPage.invalid.reset.confirm'}));
            return;
        }

        setResetPasswordLoading(true);
        const os = getOperatingSystem();

        axios.post(
            "/api/reset_password/",
            {
                parameters: {
                    email: values.email,
                    code: values.code,
                    password: values.password,
                    os: os,
                    datetime: new Date()
                },
                requestName: 'reset_password',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setResetPasswordLoading(false);
            if (response.data.status === "success") {
                successMessage(intl.formatMessage({id: 'loginPage.reset.success'}));
                resetPasswordForm.resetFields();
                setShowResetPasswordModal(false);
            } else if (response.data.status === "invalidCredentials") {
                errorMessage(intl.formatMessage({id: 'loginPage.invalid.reset.credential'}));
            } else {
                console.error("Error resetting password:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setResetPasswordLoading(false);
            console.error("Error resetting password:", error);
        });
    };

    const onSignIn = (values) => {
        setLoginLoading(true);
        const os = getOperatingSystem();

        axios.post(
            "/api/login/",
            {
                parameters: {
                    email: values.email,
                    password: values.password,
                    os: os,
                    datetime: new Date()
                },
                requestName: 'login',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const userInfo = response.data.responseData;
                userInfo.os = os;
                userInfo.versionNumber = versionNumber;

                const expirationDate = new Date();
                expirationDate.setHours(expirationDate.getHours() + 10);
                setCookie('userInfo', userInfo, {path: '/', expires: expirationDate});

                // Replace the current entry in the history stack with the project page
                navigate('/dashboard', {replace: true});
            } else if (response.data.status === "invalidCredentials") {
                setLoginLoading(false);
                errorMessage(intl.formatMessage({id: 'loginPage.invalid.credential'}));
            } else {
                setLoginLoading(false);
                console.error("Error login:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setLoginLoading(false);
            console.error("Error logging in:", error);
        });
    };

    const onSignInFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Layout style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
            <Header style={{display: 'flex', justifyContent: 'flex-end', backgroundColor: '#f0f2f5'}}>
                <DropDownMenuLanguage setLocale={setLocale}/>
            </Header>
            <Content style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-200px'}}>
                {contextHolder}
                <Card title={<FormattedMessage id="loginPage.title" values={{versionNumber: versionNumber}}/>}
                      style={{width: 450}}
                >
                    <Form name="loginForm"
                          initialValues={{remember: true}}
                          onFinish={onSignIn}
                          onFinishFailed={onSignInFailed}
                          autoComplete="off"
                    >
                        <Form.Item label={<FormattedMessage id="loginPage.email"/>}
                                   name="email"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.email.des"/>}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="loginPage.password"/>}
                                   name="password"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.password.des"/>}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%',}}>
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox><FormattedMessage id="loginPage.rememberMe"/></Checkbox>
                            </Form.Item>
                            <Form.Item name="forget">
                                <Link onClick={() => setShowResetPasswordModal(true)}>
                                    <FormattedMessage id="loginPage.forgot.password"/>
                                </Link>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{width: '100%'}} loading={loginLoading}>
                                <FormattedMessage id="loginPage.loginButton"/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Modal title={<FormattedMessage id="loginPage.reset.password"/>}
                       centered
                       width="40%"
                       open={showResetPasswordModal}
                       onCancel={() => {
                           setShowResetPasswordModal(false);
                           resetPasswordForm.resetFields();
                       }}
                       okText={<FormattedMessage id="loginPage.reset.password"/>}
                       onOk={() => resetPasswordForm.submit()}
                       okButtonProps={{loading: resetPasswordLoading}}
                >
                    <Form name="resetPasswordForm"
                          form={resetPasswordForm}
                          onFinish={onResetPassword}
                    >
                        <Form.Item label={<FormattedMessage id="loginPage.email"/>}
                                   name="email"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.email.des"/>}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="loginPage.verificationCode"/>}
                                   name="code"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.verificationCode.des"/>}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="loginPage.password.new"/>}
                                   name="password"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.password.new.des"/>}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="loginPage.password.confirm"/>}
                                   name="confirmPassword"
                                   rules={[{required: true, message: <FormattedMessage id="loginPage.password.confirm.des"/>}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        </Layout>
    );
};

export default LoginPage;
